import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "HackerRank – Divisible Sum Pairs",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:53:03.000Z",
  "url": "/2018/02/hackerrank-divisible-sum-pairs/",
  "medium_post": ["O:11:\"Medium_Post\":11:{s:16:\"author_image_url\";N;s:10:\"author_url\";N;s:11:\"byline_name\";N;s:12:\"byline_email\";N;s:10:\"cross_link\";N;s:2:\"id\";N;s:21:\"follower_notification\";N;s:7:\"license\";N;s:14:\"publication_id\";N;s:6:\"status\";N;s:3:\"url\";N;}"],
  "categories": ["Exercises"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Wanted to share my 2 solutions for this nice exercise, on is with 2 loops and the other with one loop and a recursion.`}</p>
    <p>{`This is quite nice for a morning brain workout 😀`}</p>
    <h6>{`Exercise Description {#exercisedescription}`}</h6>
    <blockquote>
      <p parentName="blockquote">{`You are given an array of n integers, a0, a1`}{`…`}{`a(n-1), and a positive integer, k. Find and print the number of (i,j) pairs where i`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{}}>{`function getAmountOfPairs(n, k, nums) {  
    var counter = 0;

    for (var i = 0; i<nums.length-1 ; i++) {
        for (var j=i+1 ; j<nums.length ; j++) {
            if ((nums[i]+nums[j])%k == 0) {
                counter++;
            }   
        }
    }
    return counter;
}


function getAmountOfPairsRecursive(k, nums) {  
    var counter = 0;
    var elm = nums.shift();

    for (var i = 0; i<nums.length ; i++) {
        if ((elm+nums[i])%k == 0) {
            counter++;
        }   
    }
    if (nums.length==0) {
        return counter;
    } else {
        return counter + getAmountOfPairsRecursive(k, nums);
    }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      